// @import "bootstrap/scss/bootstrap";
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

/*
*******************************************
Template Name: Yourlife Insurance
Author: Ramees
Date: 26-02-2024
Template By: Vandalay Designs
Copyright 2020-2023 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

*/

/*
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - IMPORTS 
03 - MIXIN 
04 - FOOTER 

05 - MEDIA QUERY
 
============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/
$colorPrimary: #0070ED;
$colorScondary: #00BCF9;
$colorBlack: #000;
$colorWhite: #fff;
$colorGray: #595959;
$colorlightGray: #ebebeb;



/*================================================
02 - IMPORTS
==================================================*/

/*FONTS*/
@import "bootstrap/scss/bootstrap";


.SSN_V3 {

    // body {
    //     font-family: 'Roboto', sans-serif;
    //     font-size: 14px;
    // }
    // * {
    //     padding: 0;
    //     margin: 0;
    //   }
    .validation-message{
        margin-top: -18px;
    }
    p {
        font-size: 14px;
    }

    a {
        text-decoration: none;
    }

    ul,
    li {
        padding: 0;
        margin: 0;
    }

    .text-justify {
        text-align: justify
    }

    .bg-gradient {
        background-image: linear-gradient(180deg, #1b9aaa, #1b9aaa6e) !important;

    }

    header {
        border-bottom: 1px solid #333;
    }




    $color1 : #1b9aaa;
    $color2 : #e1e1e1;
    $color3: #111;
    $background-color_1: #fee6e3;
    $background-color_2: #111;
    $background-color_3: #ffdeda;
    $background-color_4: #106afd;





    // body {
        margin: auto;
        max-width: 420px;

        .main {
            background-color: #ffe0da;
            min-height: 100vh;
            position: relative;
            display: ruby-text;

            .popup-wrap {
                text-align: center;
                background: url(../img/bg.png) no-repeat bottom center;
                background-size: cover;
                // background-position: center;
                // background-repeat: no-repeat;
                height: 25vh;
                width: 100%;
                position: relative;
            }

            .back {
                position: absolute;
                right: 0;
                left: 10px;
                top: 5px;
                bottom: 0;

            }

            .slides {
                background: url(../img/bg-2.png);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                text-align: center;
                color: $color3 ;
                padding: 0 15px 15px;
                z-index: 11;

                .slide-loader {
                    .txt_p {
                        color: #fe7c67 !important;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .profile {
                    padding: 0;
                    color: #444444 !important;
                    margin-bottom: 5px;

                    img {}

                    h2 {
                        font-size: 18px;
                        color: #444444 !important;
                        margin-bottom: 0;
                    }

                    p {

                        font-size: 13px;
                        color: #444444 !important;

                        strong {
                            span {
                                font-size: 18px;
                            }
                        }
                    }

                    color: $color2 !important;
                }

                .text-popup {
                    padding: 0 5px 5px;

                    h3 {
                        text-align: left;
                        color: $color3;
                        line-height: 30px;
                        font-family: "Playfair Display", serif;
                        border-bottom: dashed 1px;
                        padding-bottom: 10px;
                    }

                    .text-plc {
                        width: 100%;
                        border: 0;
                        border-radius: 1px;
                        color: $color3;
                        font-size: 16px;
                        text-align: left;
                        font-weight: 500;
                        height: 50px;
                        z-index: 999;
                        background-color: #fff;
                        border-radius: 5px;
                        border: 2px solid #fe7c67;
                    }

                    label {
                        color: $color3;
                        font-size: 16px;
                        font-weight: bold;
                        text-align: left;
                        width: 100%;
                        margin-bottom: 5px;
                    }

                    .form-control {
                        background: #ffffff;
                        border: 0px;
                        color: $color3;
                        font-size: 18px;
                        border: 2px solid #fe7c67;
                    }

                    input {
                        margin: 0;
                        font-family: inherit;
                        font-size: 18px;
                        line-height: inherit;
                        width: 100%;
                        background: #fff;
                        border: 2px solid #fe7c67;
                        border-radius: 5px;
                    }

                    input[type=file]::file-selector-button {
                        margin-right: 20px;
                        border: none;
                        background: #01bd01;
                        padding: 10px 20px;
                        color: #fff;
                        cursor: pointer;
                        transition: background .2s ease-in-out;
                    }

                    p {
                        color: $color3;

                        img {
                            width: 10px;
                            cursor: pointer;
                        }
                    }

                    .fs-14 {
                        font-size: 14px;
                    }

                    .fs-16 {
                        font-size: 16px;
                        font-family: "Playfair Display", serif;
                    }

                    .fs-18 {
                        font-size: 18px;
                    }

                    h5 {
                        font-size: 16px;
                        color: $color3 !important;
                    }

                    .font-16 {
                        font-size: 16px;
                        text-align: left;
                    }

                    .dark {
                        color: #111;
                    }

                    // 
                    ul {
                        li {
                            color: #eeeeee;
                            list-style: none;
                            font-size: 14px;
                            text-align: left;
                            padding: 5px 0 5px 20px;
                            background: url('../img/check-mark.png') no-repeat left center;
                            border-bottom: dashed 1px #565656;
                        }
                    }

                }

                p {

                    color: $color2;

                    img {
                        width: 10px;
                        cursor: pointer;
                    }
                }

                .btn-div {
                    position: fixed;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 10px;
                    width: 95%;
                    max-width: 420px;

                    button {
                        width: 100%;
                        height: 50px;
                        border: none;
                        border-radius: 1px;
                        font-size: 16px;
                        font-weight: 500;
                        background: #ff735c;
                        color: #fff;
                        max-width: 375px;
                        border-radius: 4px;

                    }

                    .cancel_but {
                        width: 28%;
                        max-width: inherit;
                        background-color: #2a2a2a;

                    }

                    .submit_but {
                        width: 62%;
                        max-width: inherit;
                    }
                }

                .p_re {
                    position: relative !important;
                }

                h2 {
                    color: $color2;
                }

                h6 {
                    color: $color2;
                }

            }
        }
    // }



    @media screen and (max-width: 991px) {
        body {
            max-width: 100%;

            .btn-div {
                button {
                    max-width: 100% !important;
                }
            }
        }
    }

    @media screen and (max-width: 512px) {

        .main {
            .slides {
                .text-popup {
                    h3 {
                        font-size: 26px;
                    }
                }
            }
        }

        .btn-div {
            button {
                height: 55px !important;
            }
        }
    }

    @media screen and (max-width: 400px) {
        .popup-wrap {
            height: 150px !important;
        }

        .btn-div {
            .submit_but {
                width: 70% !important;
            }
        }
    }


}